body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Lato', sans-serif;
}

.navAlign {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}


a { cursor: pointer; }

.app-container {
    min-height: 320px;
}

.admin-nav {
    padding-top: 0;
    padding-bottom: 0;
    background-color: #e8e9ea;
    border-bottom: 1px solid #ccc;
}

.password-icon {
    display: flex;
    align-items: center;
  
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
    width: 20px;
  
    color: #f9f9f9;
    transition: all 0.2s;
  }
  
.password-icon:hover {
    cursor: pointer;
    color: #ff4754;
}
  
.password-icon .feather-eye-off {
    display: none;
  }

.avatar{
  width: 80px;
  height: 80px;
}

.avatarProfil{
  width: 120px;
  height: 120px;
}

.fontComponent{
  font-size: 13px;
}

.addBtn{
  background-color : #0855C9;
}

.addBtn:hover{
  background-color : #0855C9;
}

.editBtn{
  
  color : orange;
}

.editBtn:hover{
  color : orange;
  text-decoration: none;
}

.deleteBtn{
  
  color : red;
}

.deleteBtn{
  color : red;
  text-decoration: none;
}

h1{
  margin-top: 10px;
}

.keep{
  display: none;
}

.keep1{
  /* background-color: #E7ECFF; */
  margin-right: 3px;
  color: white;
}

.keep3{
  background-color: #0855C9;
  margin-left: 10px;
  
}
.keep4{
  width: 17px;
  height: 17px;
}

.deleteCertification{
  margin-left: 25px;
}

.item {
  position:relative;
  padding-top:20px;
  display:inline-block;
}

.notify-badge{
  position: absolute;
  right:92px;
  top:50px;
  background: #0855C9;
  text-align: center;
  
  color:white;
  padding: 0px;
  margin: 0px;
  padding: 0px 10px;

  
  font-size : 17px;

  
}
.notify-badge-negative{
  position: absolute;
  right:86px;
  top:50px;
  background: #0855C9;
  text-align: center;
  
  color:white;
  padding: 0px;
  margin: 0px;
  padding: 0px 10px;

  font-size : 17px;
}
.competenceForm{
  border: 1px solid #0855C9;
  border-radius: 8px;
  color:#0855C9;
  background:white;
  margin-top: 5px;
  font-size : 15px;
}

.competenceForm2{
  /* display: none; */
  opacity: 0;
}
.competenceForm2:hover{
  border-radius: none;
  display:none;
}

.fontCompetences{
  height: 125px;
}

.fontBox{
  background : #cde3f2;
  border-radius: 10px;
}

.fontRemoveButton{
  border-radius: 50%;
  position: relative;
  left: 110px;
  bottom: 40px;
  background:#ff4754;
}

.fontRemoveButtonFormation{
  border-radius: 50%;
  position: relative;
  left: 110px;
  bottom: 210px;
  background:#ff4754;
}
.fontRemoveButtonExperience{
  border-radius: 50%;
  position: relative;
  left: 110px;
  bottom: 295px;
  background:#ff4754;
}

.fontRemoveButtonCertification{
  border-radius: 50%;
  position: relative;
  left: 125px;
  bottom: 210px;
  background:#ff4754;
}
.fontRemoveButtonEntretien{
  border-radius: 50%;
  position: relative;
  left: 110px;
  bottom: 125px;
  background:#ff4754;
}
.fontRemoveButtonRetour{
  border-radius: 50%;
  position: relative;
  left: 125px;
  bottom: 125px;
  background:#ff4754;
}

.fontColorWhite{
  margin-left : 15px;
  font-weight: bold;
}

.fontRemoveButton2{
  display: none;
}

.competenceForm:hover{
  border: 1px solid #0855C9;
  border-radius: 8px;
  color:#0855C9;
  background:white;
  margin-top: 5px;
  font-size : 15px;
}

.profilForm{
  margin-left: 20px;
}
.dctForm{
  background: #E7ECFF;
  padding: 15px;
}
.dctFormChildren{
  background:whitesmoke;
  color: #0855C9;
  
}
.dctTitles{
  text-decoration: underline;
  color: #25b9cc;

}
.dctTitles2{
  text-decoration: underline;
  color:white;
  background: #25b9cc;
  width:fit-content;
  
}
.logo_ax{
  width: 120px;
  height: 120px;
}
.headerCard{
  margin-top:10px;
  margin-right: 50px;
}
.headerCardUpdate{
  margin-top:10px;
  margin-left: 50px;
}

.detailsCandidateImage{
  width: 20px;
  height: 20px;
  margin-left: 20px;
}

.detailsCandidateForm{
  margin-left: 120px;
}

.iconVisible {
  cursor: pointer;
}

.styleButtonUser{
  margin-right: 10px;
}

.requiredField{
  color:#ff4754;
}

#driverLicenceYes{
  margin-right: 5px;
}

#driverLicenceNo{
  margin-left: 20px;
  margin-right: 5px;
}

.flagStyle{
  
  width: 45px;
  height: 45px;
  margin-right: 10px;
}



