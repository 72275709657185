.navbar {
    background-color: whitesmoke;
    height: 80px;
    display: inline-flex;
    width: 100%;
    align-items: center;
    box-shadow: 3px 3px 2px #ABCDFF;

  }

  .navbar-bar{
    width: 10%;
    justify-content: start;

    /* border: 1px solid; */
  }
  
  .menu-bars {
    margin-left: 2rem;
    font-size: 2rem;
    background: none;
    /* //border: 1px solid; */
  }
  
  .nav-menu {
    background-color: white;
    width: 250px;
    height: 100vh;
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    left: -100%;
    transition: 850ms;
    box-shadow: 1px 1px 8px #555;
    /* //overflow: visible; */
    z-index: 9999;
    /* //border: 1px solid; */
  }


  .nav-home {


     background-color: white;
    /* // width: 250px;
    // height: 100vh;
    // display: flex;
    // justify-content: center;
    // //position: fixed;
    // top: 0;
    // // //left: -100%;
    // transition: 850ms;
    // box-shadow: 1px 1px 8px #555; */
  }

  .nav-home.active {
    right: 0;
    transition: 350ms;
  }
  
  .nav-menu.active {
    left: 0;
    transition: 350ms;
  }
  
  .nav-text {
    display: flex;
    justify-content: start;
    align-items: center;
    padding: 8px 0px 8px 16px;
    list-style: none;
    height: 60px;
  }
  
  .nav-text a {
    text-decoration: none;
    color: #414353;
    font-size: 18px;
    width: 95%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 16px;
    border-radius: 4px;
  }
  
  .nav-text a:hover {
    background-color: #0855C9;
    color : white;
    /* #1a83ff; */
  }
  
  .nav-menu-items {
    width: 100%;
  }
  
  .navbar-toggle { 
    background-color: rgb(242, 247, 255);
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: start;
    align-items: center;
  }
  
  span {
    margin-left: 16px;
  }

  .regular_icons{
    color : '#fff';
    font-size: 1.8rem
  }
  .user{
    height:50px;
    width : 50px;
  }

  .nav_composant{
    display: flex;
    width: 80%;
    /* //flex-wrap: wrap; */
    justify-content: flex-end;
    /* //border: 1px solid */
  }

  .navbar-avatar{
    margin-left: 40px;
  }
  .navbar-icons{
    margin-left:10px;
    margin-right:10px;
    /* //height:30px; */
    padding:10px;
    
  }

  .navbar-switch-mode{
    /* //border: 1px solid; */
    width: 60px;
  }

  .navbar-mail{
    /* //border: 1px solid; */
    width: 60px;
  }
  .navbar-notification{
    /* //border: 1px solid; */
    width: 70px;
    height: 200px;
  }

  .header-connection{
    background:#0855C9;
    color:white;
    text-align: center;
  }